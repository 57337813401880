global.$ = $;
global.jQuery = $;
global.Spinner = require('spin.js');
global.amplify = require('amplifier');
import moment from 'moment';
global.moment = moment;

import 'ladda/dist/ladda-themeless.min.css';
import Ladda from 'ladda';
global.Ladda = Ladda;

global.toastr = require('toastr');
global.store = require('store');
global.tippyJs = require('tippy.js').default;
global.LazyLoad = require('./lazyload/lazyload.min');

require('tiny-date-picker/date-range-picker.css');
global.TinyDatePicker = require('tiny-date-picker');

require('tiny-date-picker/tiny-date-picker.css');
global.TinyRangePicker = require('tiny-date-picker/dist/date-range-picker');

require('intl-tel-input/build/css/intlTelInput.css');
global.intlTelInput = require('intl-tel-input');
